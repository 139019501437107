<template>
    <div>
        <CRow>
            <CCol sm="4">
                <CCard>
                    <CCardHeader>
                        <strong> Search user by criteria </strong> <small></small>
                        <div class="card-header-actions"></div>
                    </CCardHeader>
                    <CCardHeader>
                        <CInput v-c-tooltip.hover.click="`Query might be ${queries} `"
                                @input="userLookup()" v-model="search_criteria" placeholder="query> value">
                            <template #prepend>
                                <CButton @click="userLookup()" size="sm" color="primary">
                                    <CIcon name="cil-magnifying-glass"/>
                                    Search
                                </CButton>
                            </template>
                        </CInput>
                    </CCardHeader>
                    <CListGroupItem @click="selectUser(user)" v-for="user in founded_users" href="#" class="flex-column align-items-start">
                        <div v-c-tooltip.hover.click="'Click to get logs for this user.'"
                             class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">{{user.usr_firstname}} {{user.usr_lastname}} </h5>
                        </div>
                        <ul>
                            <li>
                                Email Address: {{user.usr_email}}
                            </li>
                            <li>
                                Phone Number: {{user.usr_phonenumber}}
                            </li>
                        </ul>
                    </CListGroupItem>
                </CCard>
                <CCard>
                    <CCardHeader>
                        <strong> User's Wlan </strong> <small></small>
                        <div class="card-header-actions"></div>
                    </CCardHeader>
                    <CCardBody>
                        <CInput
                                label="Wlan Name"
                                placeholder=""
                                disabled
                                :value="wlan_data.wlan_ssid"
                        />
                        <CInput
                                label="Wlan Passphrase"
                                placeholder=""
                                disabled
                                :value="wlan_data.wlan_passphrase"
                        />
                    </CCardBody>
                </CCard>
            </CCol>

            <CCol sm="4">
                <CCard>
                    <CCardHeader>
                        <strong> Residence Information </strong> <small></small>
                        <div class="card-header-actions"></div>
                    </CCardHeader>
                    <CCardBody>

                        <CInput
                                label="Building"
                                placeholder=""
                                disabled
                                :value="user_data.bul_name"
                        />

                        <CInput
                                label="Unit"
                                placeholder=""
                                disabled
                                :value="user_data.uni_name"
                        />

                        <CInput
                                label="Property"
                                placeholder=""
                                disabled
                                :value="user_data.pro_shortname"
                        />
                    </CCardBody>
                </CCard>
                <CCard>
                    <CCardHeader>
                        <strong> Migrate User account </strong> <small></small>
                        <div class="card-header-actions"></div>
                    </CCardHeader>
                    <CCardFooter>

                        <div v-if="processing" class="lds-ripple">
                            <div></div>
                            <div></div>
                        </div>
                        <div v-else>
                            <CButton v-if="!vxur" disabled type="submit" size="sm" color="primary">
                                <CIcon name="cil-check-circle"/>
                                Migrate >>
                            </CButton>

                            <CButton v-else type="submit" @click="migrateWlan()" size="sm" color="primary">
                                <CIcon name="cil-check-circle"/>
                                Migrate >>
                            </CButton>
                        </div>
                    </CCardFooter>

                </CCard>
            </CCol>
            <CCol sm="4">
                <CCard>
                    <CCardHeader>
                        <strong> New Residence Information </strong> <small></small>
                        <div class="card-header-actions"></div>
                    </CCardHeader>
                    <CCardHeader>
                        <CInput v-c-tooltip.hover.click="'Query might be uni_name, bul_name, pro_shortname, rap_mac... '"
                                @input="residenceLookup()" v-model="search_criteria_ap" placeholder="query> value">
                            <template #prepend>
                                <CButton @click="residenceLookup()" size="sm" color="primary">
                                    <CIcon name="cil-magnifying-glass"/>
                                    Search
                                </CButton>
                            </template>
                        </CInput>

                    </CCardHeader>
                    <CListGroupItem @click="selectUser(unit)" v-for="unit in founded_units" href="#"
                                    class="flex-column align-items-start">
                        <div v-c-tooltip.hover.click="'Least synced unit becomes selected.'"
                             class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">{{unit.uni_name}} </h5>

                            <p v-if="unit.status == 'Online'  && synced_aps.includes(unit.rap_mac)"
                               style="color: #1b9e3e"> {{ap_details.status}} </p>
                            <p v-if="unit.status == 'Flagged' && synced_aps.includes(unit.rap_mac)"
                               style="color: #e2940b"> {{ap_details.status}} </p>
                            <p v-if="unit.status == 'Offline' && synced_aps.includes(unit.rap_mac)"
                               style="color: #e20b0b"> {{ap_details.status}} </p>

                            <CButton v-if="!ctrlSyncFlagAP" type="submit" size="sm" @click="apQuery(unit)" color="info">
                                🔄 with controller
                            </CButton>

                        </div>
                        <ul>
                            <li>
                                AP Mac: {{unit.rap_mac}}
                            </li>
                            <li>
                                Building Name: {{unit.bul_name}}
                            </li>
                            <li>
                                Property Name: {{unit.pro_shortname}}
                            </li>
                        </ul>
                    </CListGroupItem>

                </CCard>
            </CCol>

        </CRow>
    </div>
</template>
<script>
    import axios from "axios";

    export default {
        name: 'CreateWlan',
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.usersOpened = from.fullPath.includes('wlans')
            })
        },
        data() {
            return {

                vlanUpd: true,
                assignUser: true,
                wlan_ssid: "",
                wlan_passphrase: "",
                wlan_vlan: "",
                migrate_button: false,
                search_criteria: "",
                search_criteria_ap: "",
                founded_units: null,
                ap_mac: "",
                option: null,
                vxuur: false,
                queries: ['usr_id', 'usr_firstname', 'usr_lastname', 'usr_email',
                    'usr_phonenumber'],


                user_data: {
                    usr_email: '',
                    usr_firstname: '',
                    usr_lastname: '',
                    bul_name: '',
                    uni_name: '',
                    pro_shortname: '',
                    rvlan_vlanid: ''
                },
                wlan_data: {
                    wlan_ssid: '',
                    wlan_passphrase: ''
                },
                founded_users: [],
                ctrlSyncFlagAP: false,
                ap_details: {},
                states: null,
                state_id: null,
                processing: false,
                cities: null,
                cit_id: null,
                selected_ap: null,
                properties: null,
                pro_id: null,
                zones: null,
                zone_id: null,
                synced_aps: [],
                queries_ap: ['rap_mac', 'uni_name', 'bul_name', 'pro_shortname'],
                search_email: '',
                vxur: false,
                ap_data: {
                    deviceName: '',
                    status: '',
                    zoneName: '',
                    wlanGroupId24: '',
                    wlanGroupId50: ''
                }
            }
        },
        methods: {
            selectUser(user) {

                axios.get(this.$baseURL + '/users/get_user?usr_id=' + user.usr_id)
                    .then((res) => {
                            this.user_data = res.data;
                            this.wlanLookup();
                        }
                    ).catch((error) => {
                    this.$toast.error(error);
                })

            },
            migrateWlan() {
                this.processing = true;
                axios.post(this.$baseURL + '/ruckus/wl/migrate', {
                    user: this.user_data,
                    new_ap_mac: this.selected_ap,
                }).then(
                    (res) => {
                        (res);
                        this.$toast.success("Successfully migrated WLAN.");
                        this.processing = false;
                    }
                ).catch((error) => {
                    console.log(error);
                    this.$toast.error("Failed to migrate WLAN :(");
                    this.processing = false;
                })
            },
            migrateEnable() {
                this.vxuur = !this.vxuur;
                if (
                    ['Online', 'Flagged'].includes(this.selected_ap.status)
                    && this.user_data.usr_email
                    && this.wlan_data.wlan_ssid
                    && this.wlan_data.wlan_passphrase
                ) {
                    this.vxur = true;
                } else {
                    this.vxur = false;
                }

            },
            apQuery(selected) {
                this.selected_ap = selected;
                this.ctrlSyncFlagAP = true;
                axios.get(this.$baseURL + '/ruckus/ap/details?ap_mac=' + selected.rap_mac + "&rctl_id=" + selected.rctl_id)
                    .then((res) => {
                        this.ap_details = res.data;
                        selected.status = res.data.status;
                        this.$toast.success("Successfully synced AP with controller");
                        this.synced_aps.push(selected.rap_mac);
                        this.ctrlSyncFlagAP = false;
                        this.migrateEnable();
                    }).catch((error) => {
                    this.ctrlSyncFlagAP = false;
                    this.$toast.error("Failed to sync AP due to " + error);
                    console.log(error);
                })


            },

            userLookup() {

                if (!this.search_criteria.includes('>')) {
                    return
                }
                var query = this.search_criteria.split('>')[0];
                var value = "";

                if (this.queries.includes(query)) {
                    value = this.search_criteria.split('> ')[1];
                }
                if (!value) {
                    return
                }

                axios.get(this.$baseURL + '/users/db/search?param=' + query + "&value=" + value)
                    .then((res) => {
                        this.founded_users = res.data;
                    }).catch(
                    (error) => {
                        console.log(error);
                    }
                )
            },
            residenceLookup() {

                if (!this.search_criteria_ap.includes('>')) {
                    return
                }
                var query = this.search_criteria_ap.split('>')[0];
                var value = "";

                if (this.queries_ap.includes(query)) {
                    value = this.search_criteria_ap.split('> ')[1];
                }
                if (!value) {
                    return
                }

                console.log(query, value);

                axios.get(this.$baseURL + '/ruckus/ap/db/search?param=' + query + "&value=" + value)
                    .then((res) => {
                        this.founded_units = res.data;
                        this.migrateEnable();
                    }).catch(
                    (error) => {
                        console.log(error);
                    }
                )


            },
            getStates() {

                axios.get(this.$baseURL + '/treeview/states')
                    .then((res) => {
                        this.states = [];
                        this.states.push({label: "Select State", value: null})
                        res.data.forEach(e => {
                            this.states.push({label: e.state_name, value: e.state_id});
                        })
                    })

            }
            ,
            getCities() {
                if (!this.state_id) {
                    return
                }

                localStorage.setItem('state_id', this.state_id);

                axios.get(this.$baseURL + '/treeview/city?state_id=' + this.state_id)
                    .then((res) => {
                        this.cities = [];
                        this.cities.push({label: "Select City", value: null})
                        res.data.forEach(e => {
                            this.cities.push({label: e.cit_name, value: e.cit_id});
                        })
                    })

            }
            ,

            getProperties() {
                if (!this.cit_id) {
                    return
                }
                localStorage.setItem('cit_id', this.cit_id);
                axios.get(this.$baseURL + '/treeview/properties?cit_id=' + this.cit_id)
                    .then((res) => {
                        this.properties = [];
                        this.properties.push({label: "Select Property", value: null})
                        res.data.forEach(e => {
                            this.properties.push({label: e.pro_shortname, value: e.pro_id});
                        })
                    })

                this.getUsers('cit_id', this.cit_id);
            }
            ,

            getZones() {
                if (!this.pro_id) {
                    return
                }
                localStorage.setItem('pro_id', this.pro_id);
                axios.get(this.$baseURL + '/treeview/zones?pro_id=' + this.pro_id)
                    .then((res) => {
                        this.zones = [];
                        this.zones.push({label: "Select Zone", value: null})
                        res.data.forEach(e => {
                            this.zones.push({label: e.zone_name, value: e.zone_id});
                        })
                    })
                this.getUsers('pro_id', this.pro_id);

            },
            createWlan() {

                this.wlan_data.wlan_passphrase = this.wlan_passphrase;
                this.wlan_data.wlan_ssid = this.wlan_ssid;
                this.wlan_data.rvlan_vlanid = this.wlan_vlan

                axios.post(this.$baseURL + '/ruckus/wl/create', {
                    ap_data: this.ap_data,
                    wlan_data: this.wlan_data,
                    usr_data: this.user_data,
                }).then((res) => {
                    console.log(res);
                    this.$toast.success("Successfully created network.");
                }).catch((error) => {
                    this.$toast.error("Failed to create network." + error.response.data.detail);
                })
            }
            ,
            wlanLookup() {

                this.wlan_data = {}
                axios.get(this.$baseURL + '/ruckus/wl/by_usr_id?usr_id=' + this.user_data.usr_id)
                    .then(
                        res => {

                            this.wlan_data = res.data;
                        }
                    ).catch((error) => {
                    error;

                })

            }
            ,
            goBack() {
                this.usersOpened ? this.$router.go(-1) : this.$router.push({path: '/wlans'})
            }
            ,
        },
        mounted() {
            this.validateSession();
            this.getStates();
        }
    }
</script>


<style scoped>
    .lds-facebook {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }

    .lds-facebook div {
        display: inline-block;
        position: absolute;
        left: 8px;
        width: 16px;
        background: #4673e7;
        animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    }

    .lds-facebook div:nth-child(1) {
        left: 8px;
        animation-delay: -0.24s;
    }

    .lds-facebook div:nth-child(2) {
        left: 32px;
        animation-delay: -0.12s;
    }

    .lds-facebook div:nth-child(3) {
        left: 56px;
        animation-delay: 0;
    }

    @keyframes lds-facebook {
        0% {
            top: 8px;
            height: 64px;
        }
        50%, 100% {
            top: 24px;
            height: 32px;
        }
    }

    .lds-ripple {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }

    .lds-ripple div {
        position: absolute;
        border: 4px solid #4673e7;
        opacity: 1;
        border-radius: 50%;
        animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }

    .lds-ripple div:nth-child(2) {
        animation-delay: -0.5s;
    }

    @keyframes lds-ripple {
        0% {
            top: 36px;
            left: 36px;
            width: 0;
            height: 0;
            opacity: 1;
        }
        100% {
            top: 0px;
            left: 0px;
            width: 72px;
            height: 72px;
            opacity: 0;
        }
    }


</style>